import { FundsPage } from "@components/Funds";
import { NextPageContext, NextPage } from "next";
import dynamic from "next/dynamic";
import NotFoundPage from "pages/404";

import { SPECIAL_FUNDRAISER_NONPROFIT_SLUG } from "@every.org/common/src/entity/constants";
import { NonprofitDisplayType } from "@every.org/common/src/entity/types";
import { TopLevelEntityKind } from "@every.org/common/src/routes/topLevel";

import { getTopLevelEntityInitialProps } from "src/utilities/ssr/getTopLevelEntityProps";
import { TopLevelPagePropsReturnType } from "src/utility/apiClient/topLevelEntityPage";

const ArchivedNonprofitPage = dynamic(
  () => import("src/pages/ArchivedNonprofit")
);
const NonprofitPageV3 = dynamic(
  () => import("src/pages/Nonprofit/NonprofitPageV3")
);
const CommunityPage = dynamic(() => import("@components/Community"));
const DirectoryPage = dynamic(() => import("src/pages/Directory"));
const FundraiserPage = dynamic(() => import("src/pages/Fundraiser"));
const UserPage = dynamic(() => import("src/pages/User"));
const TagFeedPage = dynamic(() => import("src/pages/Tags/TagFeedPage"));
const Community2023Page = dynamic(() => import("src/pages/Community2023"));

export const getServerSideProps = async (ctx: NextPageContext) => {
  const props = await getTopLevelEntityInitialProps(ctx);
  return {
    props,
  };
};

const UsernameOrNonprofitOrFundraiserPage: NextPage<
  TopLevelPagePropsReturnType
> = (props) => {
  switch (props.kind) {
    case TopLevelEntityKind.NONPROFIT: {
      const nonprofits = props.initialData?.nonprofits;
      if (!nonprofits) {
        return <NotFoundPage />;
      }
      const nonprofit = nonprofits[0];
      if (!nonprofit) {
        return <NotFoundPage />;
      }
      const slug = nonprofit.primarySlug;
      if (slug === SPECIAL_FUNDRAISER_NONPROFIT_SLUG) {
        return <NotFoundPage />;
      }
      if (nonprofit.archived) {
        return <ArchivedNonprofitPage nonprofit={nonprofit} />;
      }
      if (nonprofit.displayType === NonprofitDisplayType.FUND) {
        return <FundsPage fund={nonprofit} />;
      }
      if (nonprofit.displayType === NonprofitDisplayType.COMMUNITY) {
        // only use new (2023) community pages for select communities (e.g. TED 2023) until we are sure we want to switch over
        if (nonprofit.metadata?.community?.use2023Style) {
          return <Community2023Page community={nonprofit} />;
        }
        return <CommunityPage community={nonprofit} />;
      }
      if (nonprofit.displayType === NonprofitDisplayType.DIRECTORY) {
        return <DirectoryPage nonprofit={nonprofit} />;
      }
      return (
        <NonprofitPageV3
          nonprofit={nonprofit}
          contributorList={props.feedItems || []}
          contributorListHasMore={props.feedHasMore}
          initialFundraisers={props.initialFundraisers}
        />
      );
    }
    case TopLevelEntityKind.USER: {
      const username = props.userInfo.user.username;
      if (!username) {
        return <NotFoundPage />;
      }
      const donationsFeed =
        ("donationsFeed" in props && props.donationsFeed) || [];
      return (
        <UserPage
          username={username}
          userInfo={props.userInfo}
          donationsFeed={donationsFeed}
        />
      );
    }
    case TopLevelEntityKind.FUNDRAISER: {
      const fundraiserSlug = props.fundraiserData.fundraiser.slug;
      const nonprofitSlug = props.initialData.nonprofits[0]?.primarySlug;
      return (
        <FundraiserPage
          {...props}
          fundraiserSlug={fundraiserSlug}
          nonprofitSlug={nonprofitSlug}
        />
      );
    }
    case TopLevelEntityKind.CAUSE: {
      const tag = props.initialData?.tags
        ? props.initialData?.tags[0]
        : undefined;
      const initialFeedItems = props.feedItems;
      if (!tag) {
        return <NotFoundPage />;
      }
      return <TagFeedPage tag={tag} initialFeedItems={initialFeedItems} />;
    }
  }
};

export default UsernameOrNonprofitOrFundraiserPage;
