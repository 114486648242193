import * as t from "io-ts";

import { HttpMethod } from "../helpers/http";

import { fundraiserFullResponseCodec } from "./fundraiser";
import { getNonprofitResponseCodec } from "./nonprofit";
import { getTagResponseBodyCodec } from "./publicCached";
import { getUserResponseBodyCodec } from "./users";

import { makeRouteSpec } from ".";

export enum TopLevelEntityKind {
  NONPROFIT = "NONPROFIT",
  USER = "USER",
  FUNDRAISER = "FUNDRAISER",
  CAUSE = "CAUSE",
}

// We need to include a kind literal attribute in the response to
// discriminate each entity otherwise io-ts fail because it's a
// large union.
export const getTopLevelEntityRouteSpec = makeRouteSpec({
  path: "/entity/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.union([
    t.intersection([
      t.type({ kind: t.literal("NONPROFIT") }),
      getNonprofitResponseCodec,
    ]),
    t.intersection([
      t.type({ kind: t.literal("USER") }),
      getUserResponseBodyCodec,
    ]),
    t.intersection([
      t.type({ kind: t.literal("FUNDRAISER") }),
      fundraiserFullResponseCodec,
    ]),
    t.intersection([
      t.type({ kind: t.literal("CAUSE") }),
      getTagResponseBodyCodec,
    ]),
  ]),
  publicRoute: {
    publicCacheLengthMinutes: 1,
    alsoCacheIfAuthenticated: true,
  },
});
